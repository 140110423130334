<template>
    <modal ref="modalEliminacionProductos" icon="box" titulo="Eliminación de productos" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-12 text-general f-600 f-17 text-center">
                Administradores con mayor cantidad de Productos eliminados del sistema
            </div>
        </div>
        <div class="row mx-0 my-3" />
        <div v-if="usuariosEliminan.length > 0">
            <div v-for="usuario,index in usuariosEliminan" :key="index" class="row mx-0">
                <div class="col-auto px-0">
                    <img :src="usuario.usuario.foto" style="height:50px;width:50px;" class="obj-cover br-5" />
                </div>
                <div class="col">
                    <p class="text-general mb-1 f-15">
                        {{usuario.usuario.nombre}}
                    </p>
                    <p class="text-general2 mb-1 f-14">
                        {{usuario.total}} Productos
                    </p>
                    <div class="row mx-0" style="height:10px;">
                        <div class="col-12 px-0 h-100 d-flex">
                            <div class="h-100 bg-gris br-10" :style="`width:${usuario.porcentaje}%;`" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </modal>
</template>

<script>
export default {
    data(){
        return {
            url_image: 'https://www.ashoka.org/sites/default/files/styles/medium_1600x1000/public/thumbnails/images/daniela-kreimer.jpg?itok=R89tVtb4',
            usuariosEliminan : []      
        }
    },
    methods: {
        toggle(usuariosEliminan = []){
            this.usuariosEliminan = usuariosEliminan
            console.log(usuariosEliminan)
            this.$refs.modalEliminacionProductos.toggle();
        }
    }
}
</script>

<style>

</style>